<template>
  <ModalAlert
    :show="props.show"
    title="購入完了"
    size="lg"
    :is-enable-ok="false"
    :close-button="props.closeButton"
    @close="onClose"
  >
    <div class="flex flex-col w-full h-full text-center items-center justify-center">
      <div class="w-full mb-4">
        アイテムの購入が完了しました
      </div>
      <div class="p-3 w-full">
        <div v-for="(it, k) of shopChangeDataList" :key="k" class="mb-3">
          <CommonChangeValue :title="it.name" :before="it.before" :after="it.before + it.value" />
        </div>
      </div>

      <div v-if="!props.closeButton" class="flex justify-center">
        <CommonButton type="secondary" class="modal-btn flex flex-col items-center justify-center" @click="onClose">
          <div>
            キャンセル
          </div>
        </CommonButton>
        <CommonButton type="primary" class="modal-btn flex flex-col items-center justify-center" @click="onOk">
          <div>
            {{ props.okButtonText }}
          </div>
        </CommonButton>
      </div>
    </div>
  </ModalAlert>
</template>

<script lang="ts" setup>
import {
  ShopItem,
  UserItem,
  ShopItemBuyResponseData,
} from 'api';
import { getShopChangeResultData, ShopChangeData } from '~/libs/page/shop/type';
import { ShopBuyCost } from '~/composables/useWallet';

interface Props {
  show: boolean,
  shopItem: ShopItem,
  userItem?: UserItem,
  buyResponse?: ShopItemBuyResponseData,
  buyCount: number,
  cost: ShopBuyCost,
  closeButton?: boolean,
  okButtonText?: string,
}
const props = withDefaults(defineProps<Props>(), {
  userItem: undefined,
  buyResponse: undefined,
  closeButton: true,
  okButtonText: undefined
});
interface Emits {
  (e: 'onClose', shopItem: ShopItem): void;
  (e: 'onOk'): void;
}
const emit = defineEmits<Emits>();

const shopChangeDataList = ref<ShopChangeData[]>([]);
/**
 * 初期化処理
 */
const init = (data: Props) => {
  // 変化量を計算
  if (data.buyResponse) {
    shopChangeDataList.value = getShopChangeResultData({
      buyResponse: data.buyResponse,
      userItem: data.userItem,
      userStatus: useUser().userStatus.value,
      wallet: useWallet().wallet.value,
      animateWallet: useAnimateWallet().wallet.value,
    }, {
      itemCount: true,
      roomAvatarMax: true,
      avatarP: true,
      gesoCoin: true,
      gem: true,
      gold: true,
    });
  }
};
onMounted(() => {
  init(props);
});
watch(props, data => init(data));
/**
 * 完了
 */
const onClose = () => {
  emit('onClose', props.shopItem);
};

const onOk = () => {
  emit('onOk');
};
</script>
